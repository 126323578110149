<template>
  <v-container>
    <div class="header">
      <div class="combo_section">
        <v-select dense outlined  rounded class="combobox ma-1"
          id="farm" 
          ref="farm" 
          label="농장✽" 
          v-model="farm"
          :items="farmItems"
          :menu-props="{ top: false, offsetY: true }"
          no-data-text="자료(권한)이 없습니다."
          item-text="name"
          return-object
          @focus="comboEsgFarms()"
          >
          <template v-slot:item="slotProps" >
            {{slotProps.item.name}}<span class="ml-1 items">{{slotProps.item.farmNo}}</span>
          </template>
        </v-select>

        <v-menu
          v-model="menu"
          :nudge-right="10"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="300"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field dense outlined class="calendar shrink rounded mt-1 ml-2  mb-1 mr-1"
              id="to_date" 
              ref="to_date" 
              v-model="to_date"
              label="기준월"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            no-title 
            locale="ko-KR"
            v-model="to_date"
            type="month"
            @input="menu = false"
          ></v-date-picker>
        </v-menu>

        <div class="ml-1 sel_date" style="font-size:14px; font-weight:400; line-height: 45px; letter-spacing: -1px;">
          <v-icon size="14">
            mdi-calendar
          </v-icon>
          {{ sel_date.from }} - {{ sel_date.to }}
        </div>
      </div>

      <div class="combo_section radio mb-3" style="margin-top:-30px;">
        <div class="radio_content mb_radio backgr mt-3">
          <div style="margin-top:-10px">
            <v-radio-group dense outlined row 
              id="val" 
              ref="val" 
              v-model="val"
            >
              <v-radio label="연간" value="year" color="#3a4f3f"/>
              <v-radio label="출하 KG 당" value="kg" color="#3a4f3f"/> 
            </v-radio-group>
          </div>
        </div>

        <div class="mb_content">
          <div class="radio_content backsky mt-3">
            <div style="margin-top:-10px">
              <v-radio-group dense outlined row 
                id="gas" 
                ref="gas" 
                v-model="gas"
              >
                <v-radio label="전체" value="0" color="#9EB0A2"  />
                <v-radio label="메탄" value="1" color="#6B7B6E"/> 
                <v-radio label="아산화질소" value="2" color="#304E5C"/> 
                <v-radio label="이산화탄소" value="3" color="#304E5C"/> 

              </v-radio-group>
            </div>
          </div>

          <v-btn class="new-btn mt-4 ml-1" @click="monitoringEsgValueOfFarm()" :loading="loading">조회</v-btn>
        </div>
      </div>
    </div>

    <div class="chart_Layout" v-if="this.val == 'year'">
        <div class="chart_title">
          <div>{{ selectedLabel }} 배출량 </div>
          <span class="unit_content">
            <span class="unit">{{ selectedUnit }}</span>
          </span>
        </div>

      <div class="chart_container">
        <Bar
          id="Esgchart"
          :chart-options="options"
          :chart-data="emissionData"
          style="position: relative; height:300px; width:90%; margin: 0 auto; "
        />
      </div>
    </div>

    <div class="chart_Layout" v-else>
      <div class="chart_title">
        <div>{{ selectedLabel }} / 출하 Kg 당 배출량</div>
        <span class="unit_content">
          <span class="unit">{{ selectedUnit }}</span>
        </span>
      </div>
      
      <div class="chart_container">
        <Bar
          id="Esgchart"
          :chart-options="options"
          :chart-data="emissionPerKgData"
          style="position: relative; height:300px; width:90%; margin: 0 auto; "
        />
      </div>
    </div>
    <div align="end" class="mt-3 mr-10">
      <v-spacer></v-spacer>
      <v-btn class="simul-btn"   @click="route()">
        <v-icon
          size="14">
          mdi-chart-line-variant
        </v-icon>
        배출량예측하기
      </v-btn>
    </div>
  </v-container>
</template>

<script>
import Apis from '@/api/apis';
import dateUtil from "@/utils/custom/dateProperty.js";
import VueCookies from "vue-cookies";
import ChartDataLabels from 'chartjs-plugin-datalabels';

import { Bar }  from 'vue-chartjs/legacy'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, ChartDataLabels)


export default {
  name: 'MonitorEsg2',

  components: { Bar },

  async created() {
    Apis.pageCount({
      page_name: this.$route.name,
      url: this.$route.path,
      division: "pigro",
    },() => {  // 정상처리
      // console.log("pageCount 호출",res);
    }).catch( (err) => {  // 개별 API 오류 처리 예제
      console.log("pageCount 호출 오류",err);
    });
    await this.comboEsgFarms();

    let cookie_info = VueCookies.get("esg_monitor");

    if (cookie_info) {
      this.farm = cookie_info.farm || {};
      this.tier = cookie_info.tier
    } else {
      this.farm = this.farmItems[0];
      // this.tier = "2";
    }

    this.monitoringEsgValueOfFarm();
    
    let cdate = new Date();
    cdate.setMonth(cdate.getMonth());
    this.to_date= dateUtil.format(cdate, "yyyy-MM");
  },
  watch:{
    farm(){
      this.monitoringEsgValueOfFarm();
    },
    gas(newVal, oldVal) {
        if (newVal !== oldVal) this.chartEsg();
    },
    val(newVal, oldVal) {
        if (newVal !== oldVal) this.chartEsg();
    }
  },
  data(){
    return {
      loading: false,
      farm : {},
      farmItems : [],

      to_date: "",
      menu: false,

      month: "12",
      
      val : 'year',
      gas : '0',
      gaslabel : '전체',

      sel_date:{to:'', from:''},

      esgData:{},

      koreanFarmTotal:'',

      Allmanure:'',
      Kgmanure:'',

      options: {
        // responsive: false,
        maintainAspectRatio: false,
        maxBarThickness: 70, // 막대 굵기
        interaction: {
          mode: 'index',
        },
        
        plugins: {
          legend: {
            position: 'bottom',
            labels: {
              usePointStyle: true,
              font: {
                size:14,
                family: "'Pretendard'",
                lineHeight: 1
              },
            }
          },
          datalabels: {
            display: true,
            color: 'black',
            align: 'end',
            anchor: 'end',
            offset:-5,
            font: {
              family: "'Pretendard'",
              size:15,
              weight: 'number'
            },

            formatter: function(value, context) {
              let koreanFarmData = context.chart.data.datasets.map(dataset => dataset.data[context.dataIndex]);

              let total = koreanFarmData.reduce((acc, cur) => acc + cur, 0);
              if (context.datasetIndex === context.chart.data.datasets.length - 1) {
                  return total.toFixed(3);
              } else {
                  return '';
              }
            }
          }
        },
        scales: {
          x: {
            ticks: {
                font: {
                  family: "'Pretendard'",
                  size:15,
                  weight: 'bolder'
                }
            },
            stacked: true,
            grid: { 
              display: false,
              drawTicks: true, 
              tickLength: 4,
              color: '#E2E2E230' 
            },
            
          },
          y: {
            ticks: {
                font: {
                  family: "'Pretendard'",
                  size:13
                }
            },
            stacked: true,
          }
        }
      },
      emissionData: {
        labels: [],
        datasets: []
      },
      emissionPerKgData: {
        labels: [],
        datasets: []
      },

    }
  },

  computed: {
    selectedLabel() {
      const labels = {
        '0': '전체 탄소',
        '1': '메탄',
        '2': '아산화질소',
        '3': '이산화탄소'
      };
      return labels[this.gas];
    },
    selectedUnit() {
      const units = {
        '0': 'Kg CO₂ year-¹',
        '1': 'Kg CH₄ year-¹',
        '2': 'Kg N₂O year-¹',
        '3': 'Kg CO₂ year-¹'
      };
      return units[this.gas];
    },
  },


  methods:{
  route(){
    this.$router.push({
        name: "SimulationEsg",
        params: {
          farm: this.farm,
        }
      });
  },
  async monitoringEsgValueOfFarm(){
    // console.log('esg')
    this.loading = true;
    Apis.monitoringEsgValueOfFarm2({
      farmNo: this.farm.farmNo,
      stand_date: dateUtil.format(new Date(), "yyyy-MM-01"), 
      month:12,
      },(res) => {
        this.loading = false;
        this.esgData = res.data;
        this.sel_date = res.data.sel_date;

        this.Allmanure = (res.data.all.excreta.ch4.local * 28) + (res.data.all.excreta.n2o.local * 265)
        this.Kgmanure = (res.data.all_kg.excreta.ch4.local * 28) + (res.data.all_kg.excreta.n2o.local * 265)
        
        this.AllEnergy = (res.data.all.energy.co2.local) + (res.data.all.energy.ch4.local*28) + (res.data.all.energy.n2o.local*265)
        this.KgEnergy = (res.data.all_kg.energy.co2.local) + (res.data.all_kg.energy.ch4.local*28) + (res.data.all_kg.energy.n2o.local*265)

        this.chartEsg();

        this.$store.commit("resMessage",res.message);
        let cookie_info = { farm: this.farm};
        VueCookies.set("esg_monitor" ,cookie_info,"30d");

      }).catch( (err) =>{
        console.log("monitoringEsgValueOfFarm2 API 호출 오류",err)
      })
    },
    chartEsg(){
      if (this.gas == 0 && this.val == 'year') {
        this.emissionData = this.YearAllData();
      } else if (this.gas == 1 && this.val == 'year'){
        this.emissionData = this.YearCh4Data();
      }else if (this.gas == 2 && this.val == 'year'){
        this.emissionData = this.YearN2oData();
      }else if (this.gas == 3 && this.val == 'year'){
        this.emissionData = this.YearCo2Data();
      }else if (this.gas == 0 && this.val == 'kg'){
        this.emissionPerKgData = this.KgAllData();
      }else if (this.gas == 1 && this.val == 'kg'){
        this.emissionPerKgData = this.KgCh4Data();
      }else if (this.gas == 3 && this.val == 'kg'){
        this.emissionPerKgData = this.KgCo2Data();
      }else{
        this.emissionPerKgData = this.KgN2oData();
      }
    },
    YearAllData(){
      return{
          plugins: [ChartDataLabels], 
          labels: ['한국 농장 평균', '내 농장', '저탄소 농가 평균'], 
          datasets: [
            {
              label: '장내발효', 
              data:[this.esgData.all.intestinal.ch4.local*28 , this.esgData.sel.intestinal.ch4.local*28, this.esgData.low.intestinal.ch4.local*28], 
              backgroundColor: 'rgba(26, 33, 48, 0.6)',
              borderWidth: 1 
            },
            {
              label: '분뇨처리', 
              data:[this.Allmanure , this.esgData.sel.excreta.ch4.local*28 + this.esgData.sel.excreta.n2o.local*265 , this.esgData.low.excreta.ch4.local*28 + this.esgData.low.excreta.n2o.local*265 ], 
              backgroundColor: 'rgba(90, 114, 160, 0.6)', 
              borderWidth: 1 
            },
            {
              label: '에너지', 
              data:[this.AllEnergy , this.esgData.sel.energy.co2.local + this.esgData.sel.energy.ch4.local*28+ this.esgData.sel.energy.n2o.local*265 , this.esgData.low.energy.co2.local+ this.esgData.low.energy.ch4.local*28+ this.esgData.low.energy.n2o.local*265], 
              backgroundColor: 'rgba(131, 180, 255, 0.6)',
              borderWidth: 1 
            },
            // {
            //   label: '그 외', 
            //   data:[this.esgData.all.etc.local, this.esgData.sel.etc.local, this.esgData.low.etc.local], 
            //   backgroundColor: 'rgba(233, 208, 184, 0.6)', 
            //   borderWidth: 1 
            // }
          ]
      }
    },
    YearCh4Data(){
      return{
        plugins: [ChartDataLabels], 
        labels: ['한국 농장 평균', '내 농장', '저탄소 농가 평균'], 
        datasets: [
          {
            label: '장내발효', 
            data:[this.esgData.all.intestinal.ch4.local ,this.esgData.sel.intestinal.ch4.local, this.esgData.low.intestinal.ch4.local], 
            backgroundColor: 'rgba(26, 33, 48, 0.6)',
            borderWidth: 1 
          },
          {
            label: '분뇨처리', 
            data:[this.esgData.all.excreta.ch4.local , this.esgData.sel.excreta.ch4.local, this.esgData.low.excreta.ch4.local], 
            backgroundColor: 'rgba(90, 114, 160, 0.6)', 
            borderWidth: 1 
          },
          {
            label: '에너지', 
            data:[this.esgData.all.energy.ch4.local , this.esgData.sel.energy.ch4.local, this.esgData.low.energy.ch4.local], 
            backgroundColor: 'rgba(131, 180, 255, 0.6)', 
            borderWidth: 1 
          },
        ]
      }
    },
    YearN2oData(){
      return{
        plugins: [ChartDataLabels], 
        labels: ['한국 농장 평균', '내 농장', '저탄소 농가 평균'], 
        datasets: [
        {
            label: '분뇨처리', 
            data:[this.esgData.all.excreta.n2o.local , this.esgData.sel.excreta.n2o.local, this.esgData.low.excreta.n2o.local], 
            backgroundColor:  'rgba(90, 114, 160, 0.6)', 
            borderWidth: 1 
          },
          {
            label: '에너지', 
            data:[this.esgData.all.energy.n2o.local , this.esgData.sel.energy.n2o.local, this.esgData.low.energy.n2o.local], 
            backgroundColor: 'rgba(131, 180, 255, 0.6)', 
            borderWidth: 1 
          },
        ]
      }
    },
    YearCo2Data(){
      return{
        plugins: [ChartDataLabels], 
        labels: ['한국 농장 평균', '내 농장', '저탄소 농가 평균'], 
        datasets: [
        {
            label: '에너지', 
            data: [this.esgData.all.energy.co2.local , this.esgData.sel.energy.co2.local, this.esgData.low.energy.co2.local], 
            backgroundColor: 'rgba(131, 180, 255, 0.6)', 
            borderWidth: 1 
          },
        ]
      }
    },
    KgAllData(){
      return{
        plugins: [ChartDataLabels], 
        labels: ['한국 농장 평균', '내 농장', '저탄소 농장 평균'], 
        datasets: [
          {
            label: '장내발효',
            data: [this.esgData.all_kg.intestinal.ch4.local*28 , this.esgData.sel_kg.intestinal.ch4.local*28, this.esgData.low_kg.intestinal.ch4.local*28], 
            backgroundColor: 'rgba(29, 93, 155, 0.6)',
            borderWidth: 1
          },
          {
              label: '분뇨처리', 
              data:[this.Kgmanure , this.esgData.sel_kg.excreta.ch4.local*28 + this.esgData.sel_kg.excreta.n2o.local*265 , this.esgData.low_kg.excreta.ch4.local*28 + this.esgData.low_kg.excreta.n2o.local*265 ], 
              backgroundColor: 'rgba(117, 194, 246, 0.6)', 
              borderWidth: 1 
          },
          {
            label: '에너지', 
            data: [this.KgEnergy , this.esgData.sel_kg.energy.co2.local + this.esgData.sel_kg.energy.ch4.local*28+ this.esgData.sel_kg.energy.n2o.local*265 , this.esgData.low_kg.energy.co2.local+ this.esgData.low_kg.energy.ch4.local*28+ this.esgData.low_kg.energy.n2o.local*265], 
            backgroundColor: 'rgba(244, 209, 96, 0.6)',
            borderWidth: 1
          },
          // {
          //   label: '그 외', 
          //   data: [this.esgData.all_kg.etc.local , this.esgData.sel_kg.etc.local,this.esgData.low_kg.etc.local], 
          //   backgroundColor: 'rgba(197, 255, 149, 0.6)', 
          //   borderWidth: 1 
          // }
        ]
      }
    },
    KgCh4Data(){
      return{
        plugins: [ChartDataLabels], 
        labels: ['한국 농장 평균', '내 농장', '저탄소 농가 평균'], 
        datasets: [
          {
            label: '장내발효', 
            data:[this.esgData.all_kg.intestinal.ch4.local ,this.esgData.sel_kg.intestinal.ch4.local, this.esgData.low_kg.intestinal.ch4.local], 
            backgroundColor: 'rgba(29, 93, 155, 0.6)',
            borderWidth: 1 
          },
          {
            label: '분뇨처리', 
            data:[this.esgData.all_kg.excreta.ch4.local , this.esgData.sel_kg.excreta.ch4.local, this.esgData.low_kg.excreta.ch4.local], 
            backgroundColor: 'rgba(117, 194, 246, 0.6)', 
            borderWidth: 1 
          },
          {
            label: '에너지', 
            data:[this.esgData.all_kg.energy.ch4.local , this.esgData.sel_kg.energy.ch4.local, this.esgData.low_kg.energy.ch4.local], 
            backgroundColor: 'rgba(244, 209, 96, 0.6)',
            borderWidth: 1 
          },
        ]
      }
    },
    KgN2oData(){
      return{
        plugins: [ChartDataLabels], 
        labels: ['한국 농장 평균', '내 농장', '저탄소 농가 평균'], 
        datasets: [
          {
            label: '분뇨처리', 
            data:[this.esgData.all_kg.excreta.n2o.local , this.esgData.sel_kg.excreta.n2o.local, this.esgData.low_kg.excreta.n2o.local], 
            backgroundColor:'rgba(117, 194, 246, 0.6)', 
            borderWidth: 1 
          },
          {
            label: '에너지', 
            data:[this.esgData.all_kg.energy.n2o.local , this.esgData.sel_kg.energy.n2o.local, this.esgData.low_kg.energy.n2o.local], 
            backgroundColor: 'rgba(244, 209, 96, 0.6)',
            borderWidth: 1 
          },
        ]
      }
    },
    KgCo2Data(){
      return{
        plugins: [ChartDataLabels], 
        labels: ['한국 농장 평균', '내 농장', '저탄소 농가 평균'], 
        datasets: [
        {
            label: '에너지', 
            data:[this.esgData.all_kg.energy.co2.local , this.esgData.sel_kg.energy.co2.local, this.esgData.low_kg.energy.co2.local], 
            backgroundColor:'rgba(244, 209, 96, 0.6)',
            borderWidth: 1 
          },
        ]
      }
    },

    async comboEsgFarms() {
      await Apis.comboEsgFarms({
        stand_date: dateUtil.format(new Date(), "yyyy-MM-dd"), 
        },(res) => {  
          if (res.result) {
            this.farmItems = [];
            for (let i in res.data) {
            this.farmItems.push({
              code: res.data[i].farm.code,
              name: res.data[i].farm.name,
              farmNo: res.data[i].farm.farmNo
              });
            }  
          } else {
            alert(res.message);
          }
        }).catch( (err) => {  // API 오류 처리
            console.log("comboFarms API 호출 오류",err)
        }
      ) 
    },
  }
}
</script>
<style lang="scss" scoped>
::v-deep .v-label{
  letter-spacing: -1.5px;
}
::v-deep .v-btn--active{
  background-color:#3a4f3f !important;
}
::v-deep .v-date-picker-table__current {
  color:#3a4f3f !important;
}
.td_text_size{
  font-size:10px !important;
}
.radio_content{
  border: 1px solid #e4e4e4;
  border-radius: 30px;
  height:45px;
}
.backgr{
  background-color:#fafcf0;
}
.backsky{
  background-color:#ebf7eec2;
  margin-left:10px;
}
.header{
  border-radius: 10px;
}
.unit_content{
  font-size:15px;
  letter-spacing: -1px;
  font-weight:500;
  color:#3a4f3f;
  display: inline-block; 
  width: 98%; 
  text-align: right;
}
.unit{
  background-color:#e1ece4d1;
  padding:5px;
  border-radius: 10px;
  margin-right:50px;
}
.combo_section{
  display:flex;
  justify-content: center;
}
.v-menu__content{
  max-width:200px !important;
}
.combobox{
  max-width:200px;
}
.calendar{
  height:40px;
  max-width:90px;
  border-radius: 50px !important;
}
.items{
  font-size:13px;
}
.chart_Layout, .table_content{
  // margin-top:10px !important;
  margin:10px 50px 0 50px;
  padding:10px;
  border:1px solid #e4e4e4;
  // background-color:#f9fdfee5;
  border-radius: 30px;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
}
.chart_title{
  font-size:18px;
  padding:10px;
  font-weight:600;
  letter-spacing: -1px;
  margin: 5px 0 10px 10px;
}
.table_title{
  font-size:18px;
  font-weight:600;
  margin:5px 15px;
}

.table_Layout{
  display:flex;
  letter-spacing: -1px;
  justify-content: center;
  padding:10px !important;
  background-color:white;
}
.table tr th{
  text-align:center;
}
// .table th{
//   background:#47604d;
//   color:white;
//   padding:10px;
//   text-align:left;
//   width:180px;
//   border-radius: 15px;
// }
.table td{
  border:1px solid #3a4f3f;
  color:#3a4f3f;
  padding:10px;
  width:120px;
  font-size:17px !important;
  text-align: right;
  border-radius: 15px;
}
.new-btn{
  border-radius: 30px;
  color:white !important;
  font-size:12pt;
  font-weight: bold !important;
  background-color: #3a4f3f !important;
}
.mb_content{
  display:flex;
}
.simul-btn{
  background-color:#e5f0e8c2 !important;
  border-radius: 30px;
  font-size:13px;
  letter-spacing: -0.75px;
  color:#3a4f3f;
  font-weight:700;
  width:100px;
}

@media all and (max-width:479px) {
  .sel_date{
    display:none;
  }
  .radio{
    flex-direction: column; 
  }
  .combo_section{
    align-items: center;
  }
  .radio_content{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .mb_radio{
    width:90% !important;
  }
  .backsky{
    margin-left:0px;
  }
}

</style>